import { VideoDataType } from '~store/useTutorialStore';

// TODO:
// orden y descripciones

export const TUTORIALS_LIST_AUTH_USER: { [key: string]: VideoDataType } = {
	direcciones: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/direcciones.mp4?alt=media',
		name: 'Libreta de Direcciones',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/direcciones-poster.png?alt=media',
		description: 'Guarda y organiza tus direcciones frecuentes'
	},
	empaques: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/empaques.mp4?alt=media',
		name: 'Empaques',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/empaques-poster.png?alt=media',
		description: 'Administra tus empaques y contenido'
	},
	enviar: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/enviar.mp4?alt=media',
		name: 'Enviar',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/enviar-poster.png?alt=media',
		description: 'Aprende a generar una guía para tus envíos'
	},
	recolectar: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/recolectar.mp4?alt=media',
		name: 'Recolectar',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/recolectar-poster.png?alt=media',
		description: 'Entérate de como programar una recolección'
	},
	cotizar: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/cotizar.mp4?alt=media',
		name: 'Cotizar',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/cotizar-poster.png?alt=media',
		description: 'Revisa coberturas y costos de envio'
	},
	envios: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/envios.mp4?alt=media',
		name: 'Historial Envíos',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/envios-poster.png?alt=media',
		description: 'Podras revisar el seguimiento a detalle de cada envio'
	},
	recolecciones: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/recolecciones.mp4?alt=media',
		name: 'Historial Recolecciones',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/recolecciones-poster.png?alt=media',
		description: 'Revisa el estatus/seguimiento de cada recolección'
	},
	usuarios: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/usuarios_grupos.mp4?alt=media',
		name: 'Usuario',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/usuarios_grupos-poster.png?alt=media',
		description: 'Crea y administra nuevos usuarios para que utilicen la plataforma'
	},
	grupos: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/usuarios_grupos.mp4?alt=media',
		name: 'Grupo',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/usuarios_grupos-poster.png?alt=media',
		description: 'Organiza tus usuarios por grupos para asignar permisos segun su departamento'
	},
	pedido: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/pedido.mp4?alt=media',
		name: 'Pedido Guías',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/pedido-poster.png?alt=media',
		description: 'Solicita una carga de guías prepago '
	},
	stock: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/stock.mp4?alt=media',
		name: 'Stock Inventario',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/stock-poster.png?alt=media',
		description: 'Asigna y administra tus guías disponibles de tu compra'
	},
	dispersion: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/dispersion.mp4?alt=media',
		name: 'Dispersión',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/dispersion-poster.png?alt=media',
		description: 'Repartir stock'
	}
};

export const TUTORIALS_LIST_UNAUTH_USER: { [key: string]: VideoDataType } = {
	auth: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/iniciar-sesion.mp4?alt=media',
		name: 'Iniciar Sesión',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/iniciar-sesion-poster.png?alt=media',
		description: 'Como entrar a tu cuenta de Encamino'
	},
	registro: {
		url: 'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/registro.mp4?alt=media',
		name: 'Crear Cuenta',
		poster:
			'https://firebasestorage.googleapis.com/v0/b/encamino-front.appspot.com/o/registro-poster.png?alt=media',
		description: 'Como hacerme miembre de Encamino'
	}
};
