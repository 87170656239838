import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useTutorialStore, { VideoDataType } from '../../store/useTutorialStore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMicRounded';
import { useTheme } from '~hooks/useTheme';

const TutorialButton = ({ tutorialList }: { tutorialList: { [key: string]: VideoDataType } }) => {
	const location = useLocation();
	const { setVideo, setIsOpenModal } = useTutorialStore();
	const tutorialRef = useMemo(() => Object.keys(tutorialList), [tutorialList]);
	const { theme } = useTheme();

	const source = useMemo(() => {
		const pathname = location.pathname?.slice(1);
		return tutorialRef?.includes(pathname) ? tutorialList[pathname as string] : null;
	}, [tutorialRef, location, tutorialList]);

	useEffect(() => {
		setVideo(source);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, source]);

	if (!source) return <div></div>;
	return (
		<Tooltip title='Tutorial'>
			<IconButton
				onClick={() => setIsOpenModal(true)}
				sx={{
					position: 'fixed',
					right: theme.spacing(2.5),
					bottom: theme.spacing(2.5),
					zIndex: 1201,
					color: '#181818',
					backgroundColor: '#ffffff',
					padding: '10px',
					'&:hover': {
						color: '#ffffff',
						backgroundColor: theme.palette.primary.main
					}
				}}
			>
				<HeadsetMicIcon fontSize='small' color='inherit' />
			</IconButton>
		</Tooltip>
	);
};

export default TutorialButton;
