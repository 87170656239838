import React, { SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { updatePkgSend, updatePkgRate } from '../../store/actions';
import { ID } from '~store/actions/ActionTypes';
import { useSelector } from '~store/store';

export const PackageContentFieldOptions = [
	{ title: 'Accesorios (Seguro depende del material)' },
	{ title: 'Aparatos Eléctricos' },
	{ title: 'Artesanías (Sin seguro)' },
	{ title: 'Artículos de Buceo' },
	{ title: 'Artículos Deportivos' },
	{ title: 'Artículos Jardinería' },
	{ title: 'Artículos Montaña' },
	{ title: 'Artículos Pesca' },
	{ title: 'Artículos Publicidad' },
	{ title: 'Bisutería' },
	{ title: 'Calzado' },
	{ title: 'Cosméticos y Cremas' },
	{ title: 'Documentos (Sin seguro)' },
	{ title: 'Electrodomésticos' },
	{ title: 'Electrónicos' },
	{ title: 'Equipo Automotriz' },
	{ title: 'Equipo de Cómputo' },
	{ title: 'Equipo Fotográfico' },
	{ title: 'Equipo Iluminación' },
	{ title: 'Equipo Industrial' },
	{ title: 'Equipo Laboratorio' },
	{ title: 'Equipo Médico y Hospitalario' },
	{ title: 'Exhibidores' },
	{ title: 'Fragancias (Sin seguro)' },
	{ title: 'Herramientas' },
	{ title: 'Instrumentos Musicales' },
	{ title: 'Juguetes' },
	{ title: 'Libros' },
	{ title: 'Medicamento' },
	{ title: 'Mochilas / Maletas' },
	{ title: 'Motores' },
	{ title: 'Muebles (Seguro depende del material)' },
	{ title: 'Muestras (Sin seguro)' },
	{ title: 'Paneles' },
	{ title: 'Refacciones' },
	{ title: 'Ropa' },
	{ title: 'Textiles' },
	{ title: 'Otro (Sin seguro)' }
];

interface Props {
	// Function to pass the selected value
	control: any;
	errors: any;
	defaultValue?: string;
	setValue?: (name: string, value: unknown, config?: { shouldValidate?: boolean }) => void;
	setRequireDescription?: Dispatch<SetStateAction<boolean>>;
	packageId?: ID;
	fieldName?: string;
}

/**
 * Autocomplete for "Contenido de paquete"
 */
const PackageContentField: React.FC<Props> = ({
	control,
	errors,
	defaultValue,
	setValue,
	setRequireDescription,
	packageId,
	fieldName
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { currentFlow } = useSelector((state) => state.flow);

	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					disableClearable={
						location.pathname.includes('enviar') || location.pathname.includes('cotizar')
					}
					options={PackageContentFieldOptions}
					getOptionLabel={(option) => option.title ?? ''}
					getOptionSelected={(option, value) =>
						value.title && (option.title === value.title || option.title.includes(value.title))
					}
					onChange={(_, data) => {
						onChange(data);
						let newTitle = data ? data.title : '';
						if (
							newTitle === 'Otro (Sin seguro)' ||
							newTitle === 'Muestras (Sin seguro)' ||
							newTitle === 'Otro' ||
							newTitle === 'Muestras'
						) {
							setRequireDescription && setRequireDescription(true);
						} else {
							setRequireDescription && setRequireDescription(false);
						}
						setValue && setValue('content', { title: newTitle }, { shouldValidate: true });

						if (!packageId) return;

						if (currentFlow === 'send') {
							dispatch(
								updatePkgSend(packageId, {
									content: data.title
								})
							);
						} else if (currentFlow === 'rate') {
							dispatch(
								updatePkgRate(packageId, {
									content: data.title
								})
							);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Contenido&nbsp;*'
							variant='filled'
							error={Boolean(errors?.package_content)}
							helperText={errors?.package_content?.message}
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name={fieldName ?? 'package_content'}
			control={control}
			rules={{ required: true }}
			defaultValue={defaultValue && { title: defaultValue }}
		/>
	);
};

export default PackageContentField;
