import { useContext, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import AuthContext from '~context/AuthContext';
// Components
import { TextTwo } from '~components/Headings';
import DropdownPaper from '~components/Forms/DropdownPaper';
// Utils
import { getCarrierName } from '~util/logos';
import { FollowUpLabelStatus, ShipmentStatusRef } from '~util/cellStatusColor';
import { ShipmentSendResponse } from '~store/actions/ActionTypes';
import { VIEWS_REF } from '~components/Modals/IncidentFormModal/IncidentFormModal';
import { IncidentTypeField } from '~store/IncidentStore';
import usePermissions from '~hooks/usePermissions';
import { DELIVERED_OPTIONS, IncidentType, NOT_DELIVERED_OPTIONS } from '~util/incidents';

const SHIPMENT_STATUS_LIST = Object.values(ShipmentStatusRef);

const ROOT_OR_AGENT_OPTIONS = [IncidentType.RETURN_TO_ORIGIN, IncidentType.STOPPED];

interface DefaultValues extends ShipmentSendResponse {
	incident_type?: IncidentTypeField | null;
}

const IncidentRegister = ({
	errors,
	control,
	defaultValues,
	isResume
}: {
	errors: FieldErrors;
	control: Control;
	defaultValues: DefaultValues | null;
	isResume?: boolean;
}) => {
	const { currentUser } = useContext(AuthContext);
	const { isRootOrAgent } = usePermissions();

	const options = useMemo(
		() =>
			VIEWS_REF.filter((item) =>
				defaultValues?.follow_up === FollowUpLabelStatus.DELIVERED
					? isRootOrAgent
						? DELIVERED_OPTIONS.includes(item.name) || ROOT_OR_AGENT_OPTIONS.includes(item.name)
						: DELIVERED_OPTIONS.includes(item.name)
					: isRootOrAgent
					? NOT_DELIVERED_OPTIONS.includes(item.name) || ROOT_OR_AGENT_OPTIONS.includes(item.name)
					: NOT_DELIVERED_OPTIONS.includes(item.name)
			),
		[defaultValues, isRootOrAgent]
	);

	return (
		<Grid container justifyContent='center' spacing={2}>
			<Grid item md={4} xs={12}>
				<Controller
					name='business_name'
					rules={{
						required: 'Ingresa el nombre comercial'
					}}
					as={
						<TextField
							error={Boolean(errors?.business_name)}
							fullWidth
							variant='filled'
							label='Nombre Comercial&nbsp;'
							helperText={errors?.business_name?.message}
							autoComplete='none'
							disabled
						/>
					}
					control={control}
					defaultValue={defaultValues?.customer}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Controller
					name='created_at'
					// rules={{
					// 	required: 'Ingresa todos los datos de la dirección'
					// }}
					as={
						<TextField
							error={Boolean(errors?.created_at)}
							fullWidth
							variant='filled'
							label='Fecha de escaneo&nbsp;'
							// helperText={errors?.zip_code?.created_at}
							autoComplete='none'
							disabled
						/>
					}
					control={control}
					defaultValue={defaultValues?.last_update}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={SHIPMENT_STATUS_LIST}
							getOptionLabel={(option) => option}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => onChange(data)}
							disabled
							renderInput={(params) => (
								<TextField
									{...params}
									label='Estatus&nbsp;*'
									variant='filled'
									error={Boolean(errors?.follow_up)}
									helperText={errors?.follow_up?.message}
									autoComplete='none'
									disabled
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='follow_up'
					control={control}
					rules={{ required: 'Seleccioná un estatus' }}
					defaultValue={defaultValues?.follow_up}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={currentUser?.customer?.carriers ?? []}
							getOptionLabel={(option) => option.label}
							getOptionSelected={(option, value) => option.value === value.value}
							onChange={(_, data) => {
								onChange(data);
							}}
							disabled
							renderInput={(params) => (
								<TextField
									{...params}
									label='Transportista&nbsp;*'
									variant='filled'
									error={Boolean(errors?.carrier)}
									helperText={errors?.carrier?.message}
									autoComplete='none'
									disabled
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='carrier'
					control={control}
					rules={{ required: 'Ingresé el transportista' }}
					defaultValue={
						defaultValues?.carrier
							? {
									value: defaultValues?.carrier,
									label: getCarrierName(defaultValues?.carrier)
							  }
							: null
					}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={[]}
							getOptionLabel={(option) => option.label}
							getOptionSelected={(option, value) => option.value === value.value}
							onChange={(_, data) => onChange(data)}
							disabled
							renderInput={(params) => (
								<TextField
									{...params}
									label='Tipo de servicio&nbsp;*'
									variant='filled'
									error={Boolean(errors?.service_description)}
									helperText={errors?.service_description?.message}
									autoComplete='none'
									disabled
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='service_description'
					control={control}
					rules={{ required: 'Ingresé el tipo de servicio' }}
					defaultValue={{
						value: defaultValues?.service_description,
						label: defaultValues?.service_description
					}}
				/>
			</Grid>

			<Grid item md={6} xs={12}>
				<Controller
					name='parcel_number'
					rules={{
						required: 'Ingresa el número de guía'
					}}
					as={
						<TextField
							error={Boolean(errors?.parcel_number)}
							fullWidth
							variant='filled'
							label='N° de guía&nbsp;'
							helperText={errors?.parcel_number?.message}
							autoComplete='none'
							disabled
						/>
					}
					control={control}
					defaultValue={defaultValues?.parcel_number}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					name='tracking_number'
					// rules={{
					// 	required: 'Ingresa todos los datos de la dirección'
					// }}
					as={
						<TextField
							error={Boolean(errors?.tracking_number)}
							fullWidth
							variant='filled'
							label='Código de rastreo&nbsp;'
							helperText={errors?.zip_code?.tracking_number}
							autoComplete='none'
							disabled
						/>
					}
					control={control}
					defaultValue={defaultValues?.tracking_number}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							selectOnFocus
							clearOnBlur
							autoHighlight
							openOnFocus
							options={options}
							getOptionLabel={(option) => option.name}
							getOptionSelected={(option, value) => option?.name === value?.name}
							onChange={(_, data) => onChange(data)}
							disabled={isResume}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Tipo de Incidente&nbsp;*'
									variant='filled'
									error={Boolean(errors?.incident_type)}
									helperText={errors?.incident_type?.message}
									autoComplete='none'
									disabled={isResume}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='incident_type'
					control={control}
					rules={{ required: 'Seleccioná el tipo de incidente' }}
					defaultValue={defaultValues?.incident_type ?? null}
				/>
			</Grid>

			<Grid item xs={6} container alignItems='center'>
				<TextTwo text='Descripcion del incidente que puede tener alrededor de dos lineas, ver cual es la mas larga' />
			</Grid>
		</Grid>
	);
};

export default IncidentRegister;
