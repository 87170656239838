export const enum IncidentType {
	INCORRECT = 'Entrega Incorrecta',
	NO_MOVEMENT = 'Agilizar Entrega',
	DATA_CORRECTION = 'Datos Incompletos',
	COORDINATE_TO_OFFICE = 'Coordinar a Oficina',
	ROBBERY = 'Robo Unidad',
	SUBTRACTION = 'Sustracción de Mercancía',
	DAMAGE_MERCHANDISE = 'Mercancía Dañada',
	LOST_MERCHANDISE = 'Extravío Mercancía',
	RETURN_TO_ORIGIN = 'Devolución al Remitente',
	// For root only:
	DECOMMISED = 'Decomisado por la Autoridad',
	STOPPED = 'Revisión Autoridades'
}

export enum IncidentStatus {
	CREATED = 'Creado',
	IN_PROGRESS = 'Abierto',
	FINISHED = 'Finalizado',
	COMPENSATED = 'Indemnizado',
	NOT_APPROPRIATE = 'No Procedente'
}

enum MovementTypes {
	REPORT_INCIDENT = 'Reporte Incidente',
	DISCHARGE_CARRIER_REPORT = 'Alta Reporte Transportista',
	CALL_CENTER_CALL = 'Llamada Call Center',
	EXECUTIVE_CALL = 'Llamada Ejecutivo',
	MAIL_SENT = 'Envío de Correo',
	REGISTER_REFERENCE = 'Registro de Datos / Referencias',
	REPORT_COMPLETED = 'Reporte Concluido',

	// only merchandise incidents
	EVIDENCE_SENT = 'Envío de Evidencia',
	MAIL_RESOLUTE_LETTER = 'Correo / Carta Resolutiva',
	COMPENSATION_DOCUMENTATION = 'Registro Documentación Indemnización',
	COMPENSATION_PAYMENT_STARTED = 'Pago Indeminización en Proceso',
	COMPENSATION_PAYMENT_DONE = 'Pago Indemnización Realizado',

	MISSING_EVIDENCE = 'Falta Evidencia',
	MISSING_DATA = 'Falta de Datos',
	CUSTOMER_ERROR = 'Cliente No Completó Proceso',
	PACKAGE_ERROR = 'Empaque Inadecuado',
	FORBIDDEN = 'Artículo Prohibido',
	NO_INSURANCE = 'Mercancía Sin Seguro'
}

export const DELIVERED_OPTIONS = [
	IncidentType.INCORRECT,
	IncidentType.DAMAGE_MERCHANDISE,
	IncidentType.SUBTRACTION
];
export const NOT_DELIVERED_OPTIONS = [
	IncidentType.NO_MOVEMENT,
	IncidentType.DATA_CORRECTION,
	IncidentType.COORDINATE_TO_OFFICE,
	IncidentType.ROBBERY,
	IncidentType.LOST_MERCHANDISE,
	IncidentType.DECOMMISED
];

export const MOVEMENT_OPTIONS_REF = {
	[IncidentStatus.CREATED]: [MovementTypes.REPORT_INCIDENT],
	[IncidentStatus.FINISHED]: [MovementTypes.REPORT_COMPLETED],
	[IncidentStatus.IN_PROGRESS]: {
		data: [
			MovementTypes.DISCHARGE_CARRIER_REPORT,
			MovementTypes.CALL_CENTER_CALL,
			MovementTypes.EXECUTIVE_CALL,
			MovementTypes.MAIL_SENT,
			MovementTypes.REGISTER_REFERENCE
		],
		merchandise: [
			MovementTypes.DISCHARGE_CARRIER_REPORT,
			MovementTypes.CALL_CENTER_CALL,
			MovementTypes.EXECUTIVE_CALL,
			MovementTypes.MAIL_SENT,
			MovementTypes.REGISTER_REFERENCE,
			MovementTypes.MISSING_EVIDENCE,
			MovementTypes.MAIL_RESOLUTE_LETTER,
			MovementTypes.COMPENSATION_DOCUMENTATION,
			MovementTypes.COMPENSATION_PAYMENT_STARTED
		]
	},
	[IncidentStatus.COMPENSATED]: [MovementTypes.COMPENSATION_PAYMENT_DONE],
	[IncidentStatus.NOT_APPROPRIATE]: [
		MovementTypes.MISSING_EVIDENCE,
		MovementTypes,
		MovementTypes.MISSING_DATA,
		MovementTypes.CUSTOMER_ERROR,
		MovementTypes.PACKAGE_ERROR,
		MovementTypes.FORBIDDEN,
		MovementTypes.NO_INSURANCE
	]
};
