import { Grid, TextField, IconButton, Checkbox } from '@mui/material';
import { X } from 'react-feather';
import { Control, Controller, FieldErrors } from 'react-hook-form';

/**
 * Create incident: resume notification section
 */

const IncidentNotifications = ({
	fields,
	errors,
	control,
	remove,
	multiple
}: {
	fields: Record<string, any>[];
	errors: FieldErrors;
	control: Control;
	remove: (index: number) => void;
	multiple: boolean;
}) => {
	return (
		<Grid container spacing={2} alignItems='center'>
			{fields.map((c: any, index: number) => {
				const nameLabel = index === 0 ? 'Remitente' : index === 1 ? 'Destinatario' : 'Usuario';
				return (
					<Grid item container spacing={2} key={c.key} alignItems='flex-start'>
						{multiple && (
							<Grid style={{ marginTop: 5 }}>
								<IconButton onClick={() => remove(index)} disabled={fields?.length === 1}>
									<X />
								</IconButton>
							</Grid>
						)}
						<Grid item>
							<Controller
								control={control}
								name={`notification_contacts.[${index}].active`}
								type={'boolean'}
								defaultValue={false}
								render={({ onChange, ...props }) => (
									<Checkbox
										color={'primary'}
										checked={props.value}
										onChange={(e) => {
											onChange(e.target.checked);
										}}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={11} md={3}>
							<TextField
								name={`notification_contacts.[${index}].id`}
								style={{ display: 'none' }}
								defaultValue={null}
							/>

							<Controller
								as={
									<TextField
										fullWidth
										error={Boolean(errors?.notification_contacts?.[index]?.name)}
										variant='filled'
										label={`${nameLabel} *`}
										helperText={
											Boolean(errors?.notification_contacts?.[index]?.name) &&
											errors.notification_contacts[index]?.name?.message
										}
									/>
								}
								name={`notification_contacts.[${index}].name`}
								control={control}
								defaultValue={null}
								rules={{ required: 'Ingrese nombre de contacto' }}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Controller
								as={
									<TextField
										fullWidth
										error={Boolean(errors?.notification_contacts?.[index]?.email)}
										variant='filled'
										label='Correo&nbsp;*'
										helperText={
											Boolean(errors?.notification_contacts?.[index]?.email) &&
											errors.notification_contacts[index]?.email?.message
										}
									/>
								}
								name={`notification_contacts.[${index}].email`}
								control={control}
								defaultValue={null}
								rules={{ required: 'Ingrese el correo' }}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Controller
								name={`notification_contacts[${index}].phone_number`}
								control={control}
								rules={{ required: 'Ingrese un número de teléfono' }}
								defaultValue={null}
								as={
									<TextField
										variant='filled'
										label='WhatsApp&nbsp;*'
										error={Boolean(errors?.notification_contacts?.[index]?.phone_number)}
										helperText={
											Boolean(errors?.notification_contacts?.[index]?.phone_number) &&
											errors.notification_contacts[index]?.phone_number?.message
										}
										fullWidth
									/>
								}
							/>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default IncidentNotifications;
