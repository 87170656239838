import { useState, forwardRef, ReactElement, Ref } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide,
	CircularProgress
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions';
import { X } from 'react-feather';

const useStyles = makeStyles((theme: Theme) => ({
	spacing: {
		padding: `0 ${theme.spacing(3)}`
	},
	contentContainer: {
		minWidth: '380px',
		padding: `0 ${theme.spacing(3)}`
	},
	btn: {
		padding: theme.spacing(3)
	},
	cancelCross: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& div': {
			margin: '8px 8px 0 0',
			cursor: 'pointer',
			width: '14px',
			height: '14px',
			'& svg:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.08)',
				borderRadius: '50%',
				color: 'white'
			}
		}
	}
}));

type DialogProps = {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	bodyText: string;
	handleClickConfirm: () => void;
	handleClickCancel?: () => void;
	confirmBtnLabel?: string;
	cancelBtnLabel?: string;
	loading?: boolean;
	hideCancelBtn?: boolean;
};

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children: ReactElement<any, any> },
	ref: Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ConfirmActionDialog = ({
	isOpen,
	onClose,
	title,
	bodyText,
	handleClickConfirm,
	handleClickCancel,
	confirmBtnLabel,
	cancelBtnLabel,
	loading,
	hideCancelBtn
}: DialogProps) => {
	const classes = useStyles();

	const [actionType, setActionType] = useState<'confirm' | 'cancel'>();

	const handleConfirm = () => {
		setActionType('confirm');
		handleClickConfirm();
	};

	const handleCancel = () => {
		setActionType('cancel');
		handleClickCancel ? handleClickCancel() : onClose();
	};

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby={title ?? 'modal confirmación'}
			className={classes.spacing}
		>
			<div className={classes.cancelCross}>
				<div onClick={() => onClose()}>
					<X size={16} />
				</div>
			</div>
			{title && <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>}
			<DialogContent className={classes.contentContainer}>
				<DialogContentText id='alert-dialog-slide-description'>{bodyText}</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.btn}>
				{!hideCancelBtn && (
					<Button onClick={handleCancel} variant='outlined'>
						{loading && actionType === 'cancel' ? (
							<CircularProgress size={20} color='inherit' />
						) : (
							cancelBtnLabel ?? 'Cancelar'
						)}
					</Button>
				)}
				<Button onClick={handleConfirm} color='primary' variant='contained'>
					{loading && actionType === 'confirm' ? (
						<CircularProgress size={20} color='inherit' />
					) : (
						confirmBtnLabel ?? 'Confirmar'
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmActionDialog;
