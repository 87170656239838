import { create } from 'zustand';
import produce from 'immer';

export type VideoDataType = { url: string; name: string; poster: string; description: string };

interface Balance {
	isOpenModal: boolean;
	setIsOpenModal: (isOpen: boolean) => void;
	video: VideoDataType | null;
	setVideo: (video: VideoDataType | null) => void;
}

const useTutorialStore = create<Balance>((set) => ({
	isOpenModal: false,
	setIsOpenModal: (isOpen: boolean) =>
		set((state) =>
			produce(state, (draft) => {
				draft.isOpenModal = isOpen;
			})
		),
	video: null,
	setVideo: (video: VideoDataType | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.video = video;
			})
		)
}));

export default useTutorialStore;
