import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useIncidentStore from '~store/IncidentStore';
import axiosInstance from '~util/axios';
import axios from 'axios';
import DrawContext from '~context/DrawContext';

export const useIncidentForm = () => {
	const history = useHistory();
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const {
		label,
		incidentType,
		setContacts,
		setPackages,
		setMerchandise,
		setAddress,
		setDamagedMerchandise,
		contacts,
		packages,
		merchandise,
		address,
		damagedMerchandise,
		files
	} = useIncidentStore();
	const { setNotification } = useContext(DrawContext);

	const formatPackagePackaging = (packaging?: string) => {
		return packaging?.slice(packaging?.indexOf('/') + 1);
	};

	const saveForm = useCallback(
		async (data: { [key: string]: any }) => {
			setLoadingSubmit(true);
			if (incidentType?.type === 0 || incidentType?.type === 1) {
				const {
					city,
					neighborhood: { neighborhood },
					external_number,
					country,
					street,
					state,
					zip_code,
					reference_streets,
					reference,
					address_business_name,
					internal_number,
					location_url
				} = data;
				const address: any = {
					city,
					neighborhood,
					number: external_number,
					country,
					street,
					state,
					zip_code,
					// @ts-ignore
					reference_street: reference_streets,
					reference,
					business_name: address_business_name
				};
				if (internal_number) {
					address.suite_number = internal_number;
				}
				if (location_url) {
					address.location_url = location_url;
				}
				setAddress(address);
			}
			if (data.contacts?.length) {
				setContacts(data.contacts?.map((item: any) => ({ ...item, type: item.type?.title })));
			}

			if (data.packages?.length) {
				setPackages(
					data.packages.map((item: any) => ({
						...item,
						packaging: formatPackagePackaging(item.packaging?.name),
						description: formatPackagePackaging(item.description?.description),
						content: typeof item?.content === 'string' ? item?.content : item?.content?.title
					}))
				);
			}
			if (data.merchandise?.length) {
				setMerchandise(
					data.merchandise.map((item: any) => ({
						...item,
						packaging: formatPackagePackaging(item.packaging?.name),
						description: formatPackagePackaging(item.description?.description),
						// TODO: exravio mercancia debería dejarme mandar sin quantity
						quantity: item.quantity ? parseInt(item.quantity) : 1,
						content: item?.content,
						insurance: data?.insurance === 'Sí',
						currency: item.currency?.code
					}))
				);
			}

			if (data.damaged_merchandise?.length) {
				setDamagedMerchandise(
					data.damaged_merchandise.map((item: any) => ({
						...item,
						packaging: formatPackagePackaging(item.packaging?.name),
						description: formatPackagePackaging(item.description?.description),
						quantity: item.quantity ? parseInt(item.quantity) : 1,
						content: item?.content,
						insurance: data?.insurance === 'Sí',
						currency: item.currency?.code
					}))
				);
			}

			// Extra documents step for substraction and damaged merchandise
			const url =
				incidentType?.type !== 4
					? '/registrar-incidente/resumen'
					: '/registrar-incidente/documentos';

			setLoadingSubmit(false);
			history.push(url);
		},
		[
			history,
			incidentType?.type,
			setAddress,
			setContacts,
			setPackages,
			setMerchandise,
			setDamagedMerchandise
		]
	);

	const submitForm = useCallback(
		async (data: { [key: string]: any }) => {
			setLoadingSubmit(true);

			try {
				let payload: any = {
					label_id: label?.id,
					incident: data.incident_type?.name,
					parcel_number: data.parcel_number,
					tracking_number: data.tracking_number,
					carrier: data.carrier?.label,
					customer: data.business_name,
					status: data.follow_up,
					created_at: data.created_at,
					user_id: label?.user?.id,
					// TODO:
					description: 'descriotion'
					// business_name: 'ROBERTO GERVACIO'
				};

				if (data.incident_type.type === 0) {
					payload = {
						...payload,
						...address
						// business_name: data.address_business_name,
					};
				}

				// TO BRANCH
				if (data.incident_type.type === 1) {
					payload = {
						...payload,
						...address,
						branch_name: data.business_name
					};
				}

				if (data.incident_type.type === 0 || data.incident_type.type === 1) {
					if (contacts?.length) {
						payload = {
							...payload,
							contact_name: contacts[0].name,
							contact_phone_number: contacts[0].phone_number,
							contact_type_phone_number: contacts[0].type
						};
					}
					if (packages?.length) {
						const packageContent = packages[0]?.content?.includes('(')
							? packages[0]?.content?.slice(0, packages[0]?.content?.indexOf('(') - 1)
							: packages[0]?.content;
						payload = {
							...payload,
							packaging: packages[0]?.packaging,
							content: packageContent,
							content_description: packages[0]?.description,
							value: packages[0]?.value
						};
					}
				}

				// MERCHANDISE
				if (
					data.incident_type.type === 2 ||
					data.incident_type.type === 3 ||
					data.incident_type.type === 4
				) {
					payload.merchandise = merchandise;
				}

				// DAMAGED MERCHANDISE
				if (data.incident_type.type === 4) {
					payload.damaged_merchandise = damagedMerchandise;
					if (!!files?.length) {
						const payloadFiles = files.map((item) => ({
							type: item.type,
							// @ts-ignore
							path: item.path,
							url: URL.createObjectURL(item)
						}));

						payload.files = payloadFiles;
					}
				}

				// TODO: notification contacts
				// if (data.notification_contacts?.length) {
				// 	payload.notification_contacts = data.notification_contacts
				// }

				await axiosInstance.post('/api/ticket', payload);
				setNotification({
					message: 'Incidente creado con éxito.',
					severity: 'success'
				});
				history.push('/incidentes');
			} catch (error) {
				let message = 'Hubo un error al crear el incidente.';
				if (axios.isAxiosError(error) && typeof error.response?.data?.description === 'string') {
					message = error.response?.data?.description;
				}
				setNotification({
					message,
					severity: 'error'
				});
			} finally {
				setLoadingSubmit(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history, address, contacts, label, packages, merchandise, damagedMerchandise, files]
	);

	return {
		saveForm,
		submitForm,
		isLoading: loadingSubmit
	};
};
