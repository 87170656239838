import React, { useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Package } from '~store/actions/ActionTypes';
import { useDispatch } from 'react-redux';
import { useCardStyles } from '~hooks/useStyles';
import InputSearchPackage from '../Inputs/InputSearchPackage';
import TablePackagesCU from '../Tables/TablePackagesCU';
import {
	disableInsuranceServicesFlow,
	setInsuranceOnSummary,
	enableInsuranceServicesFlow
} from '~store/actions';
import { useSelector, RootStore } from '~store/store';
import DrawContext from '~context/DrawContext';
import { HeadingOne } from '~components/Headings';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		'&>div>h4': {
			marginRight: '4px'
		}
	}
}));

interface Props {
	isRating?: boolean;
	currentPackages: Package[];
	addInsurance: boolean;
	setAddInsurance: Dispatch<SetStateAction<boolean>>;
}

/**
 * This is the container for the TablePackagesCU
 * is used when Send or Rate
 */
const PackageCard: React.FC<Props> = ({
	isRating,
	currentPackages,
	addInsurance,
	setAddInsurance
}) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const dispatch = useDispatch();
	const { currentFlow } = useSelector((state) => state.flow);
	const { currentPackagesSend } = useSelector((state) => state.send);
	const { setNotification } = useContext(DrawContext);
	const { currentPackagesRate } = useSelector((state: RootStore) => state.rate);

	useEffect(() => {
		dispatch(disableInsuranceServicesFlow());
		dispatch(setInsuranceOnSummary(false));
	}, [addInsurance, dispatch]);

	useEffect(() => {
		const packages = currentFlow === 'send' ? currentPackagesSend : currentPackagesRate;
		if (!packages?.length || !addInsurance) {
			dispatch(disableInsuranceServicesFlow());
			dispatch(setInsuranceOnSummary(false));
			return;
		}
		if (
			packages.every(
				(pkg) =>
					pkg.content === 'Accesorios' ||
					pkg.content === 'Artesanías' ||
					pkg.content === 'Documentos' ||
					pkg.content === 'Fragancias' ||
					pkg.content === 'Muebles' ||
					pkg.content === 'Otro' ||
					pkg.content === 'Muestras'
			)
		) {
			setAddInsurance(false);
			setNotification({
				message: 'El contenido del paquete no se puede asegurar',
				severity: 'info',
				time: 4000
			});
			return;
		}
		dispatch(enableInsuranceServicesFlow());
		dispatch(setInsuranceOnSummary(true));
	}, [
		addInsurance,
		setAddInsurance,
		dispatch,
		currentPackagesSend,
		setNotification,
		currentPackagesRate,
		currentFlow
	]);

	return (
		<Grid className={clsx([classes.root, cardClasses.card])} container alignItems='center'>
			{/* Titulo */}
			<Grid item xs={12} md={3}>
				<HeadingOne style={{ paddingLeft: '4px' }} text='Empaques' />
			</Grid>

			{/* Boton */}
			<Grid item xs={12} md={9} container alignItems='center'>
				<Grid item xs={7}>
					<InputSearchPackage isRating={isRating} label='Empaque' />
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						value='end'
						control={
							<Checkbox
								color='primary'
								checked={addInsurance}
								onChange={() => setAddInsurance((prev) => !prev)}
							/>
						}
						label='Asegurar envío'
						labelPlacement='end'
						style={{ marginLeft: '4px' }}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<TablePackagesCU showInsurance={addInsurance} data={currentPackages} />
			</Grid>
		</Grid>
	);
};

export default PackageCard;
