import { alpha } from '@material-ui/core/styles';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	grow: {
		//flexGrow: 1,
		zIndex: 1100
	},
	helpButton: {
		textTransform: 'capitalize',
		color: 'white',
		borderRadius: 20,
		height: 44,
		marginTop: 10,
		marginRight: 10
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	brand: {
		//flexGrow: 0.8,
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			zIndex: 1100
		}
	},
	search: {
		position: 'relative',
		borderRadius: theme.spacing(3),
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25)
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: '500px'
		}
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		height: 30,
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch'
		}
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		}
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	appBar: {
		backgroundColor: '#181818 !important',
		boxShadow: 'none',
		'&>div': {
			display: 'flex',
			alignItems: 'center',
			minHeight: '72px'
		}
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.default
	},
	customerSelector: {
		width: '100%'
	},
	balanceData: {
		marginRight: theme.spacing(1),
		display: 'flex',
		alignItems: 'flex-start',
		// color: theme.palette.primary.main,
		'& svg': {
			marginRight: theme.spacing(1),
			fontSize: '20px'
		},
		'& p': {
			fontWeight: 'bold'
		}
	},
	divider: {
		backgroundColor: 'rgba(255, 255, 255, 0.12)'
	}
}));
