import { Fragment, FC, ReactNode, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { radiusCard } from '~hooks/useStyles';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator
} from '@material-ui/lab';
import { FollowUpLabelStatus } from '~util/cellStatusColor';
import { EventType } from '~store/actions/ActionTypes';
import moment from 'moment';
import 'moment/locale/es';

const useStyles = makeStyles((theme: Theme) => ({
	trakingContainer: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: radiusCard,
		'& li::before': {
			display: 'none'
		},
		[theme.breakpoints.down(943)]: {
			'& > div': {
				windth: '100%',
				'& div': {
					windth: '100%',
					maxWidth: 'unset'
				}
			}
		}
	}
}));
interface Props {
	service: string;
	carrier: string;
	followUp: string;

	// At this moment this works only for zubut
	events?: EventType[];
}

const EventItem = ({
	events,
	children,
	index,
	listKey
}: {
	events: EventType[];
	children: ReactNode;
	index: number;
	listKey: string;
}) => {
	return (
		<TimelineItem key={listKey}>
			<TimelineSeparator>
				{index === 0 ? (
					<Fragment>
						<TimelineDot style={{ backgroundColor: '#57BBD8' }} />
						<TimelineConnector style={{ width: '1px' }} />
					</Fragment>
				) : (
					<Fragment>
						<TimelineDot />
						{index !== events.length - 1 && <TimelineConnector style={{ width: '1px' }} />}
					</Fragment>
				)}
			</TimelineSeparator>
			<TimelineContent>{children}</TimelineContent>
		</TimelineItem>
	);
};

const TrackingContainer: FC<Props> = ({ carrier, service, followUp, events }) => {
	const eventList = useMemo(() => events?.reverse(), [events]);
	const classes = useStyles();

	return (
		<Grid container justifyContent='center' className={classes.trakingContainer}>
			<Grid item xs={12}>
				<Grid item xs={4} md={12}>
					{carrier === 'redpack' && eventList ? (
						<Timeline>
							{eventList.reverse()?.map((e, index) => (
								<EventItem
									events={eventList}
									index={index}
									key={`${e.createdAt}-${e.description}`}
									listKey={`${e.event}-${e.description}`}
								>
									<>
										{e.description || ''}
										<p style={{ marginTop: 0, color: '##969696' }}>
											{moment(e.createdAt).format('DD-MM-YYYY hh:mm:ss')}
										</p>
									</>
								</EventItem>
							))}
						</Timeline>
					) : null}

					{carrier === 'paquetexpress' && eventList ? (
						<Timeline>
							{eventList?.map((e, index) => (
								<EventItem
									events={eventList}
									index={index}
									key={`${e.event}-${e.description}`}
									listKey={`${e.event}-${e.description}`}
								>
									<>
										{e.description}
										<p style={{ marginTop: 0, color: '##969696' }}>{e.createdAt}</p>
									</>
								</EventItem>
							))}
						</Timeline>
					) : null}

					{carrier === 'fedex' && eventList ? (
						<Timeline>
							{eventList.reverse()?.map((e, index) => (
								<EventItem
									events={eventList}
									index={index}
									key={`${e.event}-${e.description}`}
									listKey={`${e.event}-${e.description}`}
								>
									<>
										{e.description || ''}
										<p style={{ marginTop: 0, color: '##969696' }}>
											{moment(e.createdAt).format('DD-MM-YYYY HH:mm:ss')}
										</p>
									</>
								</EventItem>
							))}
						</Timeline>
					) : null}

					{carrier === 'dhl' && eventList ? (
						<Timeline>
							{eventList?.map((e, index) => (
								<EventItem
									events={eventList}
									index={index}
									key={`${e.event}-${e.description}`}
									listKey={`${e.event}-${e.description}`}
								>
									<>
										{e.description || ''}
										<p style={{ marginTop: 0, color: '##969696' }}>
											{moment(e.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}
										</p>
									</>
								</EventItem>
							))}
						</Timeline>
					) : null}

					{carrier === 'estafeta' && eventList ? (
						<Timeline>
							{eventList?.map((e, index) => (
								<TimelineItem key={index}>
									<EventItem
										events={eventList}
										index={index}
										key={`${e.event}-${e.description}`}
										listKey={`${e.event}-${e.description}`}
									>
										<>
											{e.event || ''}
											<p style={{ marginTop: 0, color: '##969696' }}>
												{moment(e.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}
											</p>
										</>
									</EventItem>
								</TimelineItem>
							))}
						</Timeline>
					) : null}

					{!['redpack', 'dhl', 'estafeta', 'fedex', 'paquetexpress'].includes(carrier) &&
					eventList ? (
						<Timeline>
							<TimelineItem>
								<TimelineSeparator>
									<TimelineDot
										style={{
											backgroundColor:
												followUp === FollowUpLabelStatus.REGISTERED ? 'green' : '#646464'
										}}
									/>
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent>Registrado</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineSeparator>
									<TimelineDot
										style={{
											backgroundColor:
												followUp !== FollowUpLabelStatus.REGISTERED &&
												followUp !== FollowUpLabelStatus.DELIVERED &&
												followUp !== FollowUpLabelStatus.CANCELLED
													? 'green'
													: '#646464'
										}}
									/>
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent>En tránsito</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineSeparator>
									<TimelineDot
										style={{
											backgroundColor:
												followUp === FollowUpLabelStatus.DELIVERED ? 'green' : '#646464'
										}}
									/>
								</TimelineSeparator>
								<TimelineContent>Entregado</TimelineContent>
							</TimelineItem>
						</Timeline>
					) : null}
				</Grid>
			</Grid>
			{/* <Grid item xs={3}>
				<CarrierService carrier={carrier} service={service} />
			</Grid> */}
			{!events && (
				<p style={{ fontWeight: '400', fontSize: '14px', opacity: 0.8, textAlign: 'center' }}>
					Aún no hay eventos registrados
				</p>
			)}
		</Grid>
	);
};

export default TrackingContainer;
