import { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { HeadingOne } from '~components/Headings';
import useTutorialStore, { VideoDataType } from '~store/useTutorialStore';
import { X } from 'react-feather';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import SaveButton from '~components/Tables/Controls/SaveButton';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		// '& .MuiPaper-root': {
		// 	maxWidth: '80vw'
		// },
		padding: theme.spacing(2)
	},
	cancelCross: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& div': {
			margin: '8px 8px 0 0',
			cursor: 'pointer',
			width: '14px',
			height: '14px',
			'& svg:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.08)',
				borderRadius: '50%',
				color: 'white'
			}
		}
	},
	dialogTitle: {
		paddingTop: '0 !important',
		paddingBottom: '4px !important',
		overflow: 'hidden',
		width: '100%'
	},
	dialogContent: {
		paddingTop: '0 !important',
		overflow: 'hidden',
		height: '100%'
	},
	videos: {
		display: 'flex',
		gap: theme.spacing(1),
		height: '100%',
		overflow: 'hidden',
		'& > div': {
			height: '100% !important',
			paddingTop: 0
		},
		'& div:nth-child(2)': {
			paddingTop: theme.spacing(2)
		}
	},
	currentTitle: {
		display: 'flex',
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
			3
		)}px`,
		// margin: 0,
		'& > p:nth-child(1)': {
			marginRight: '4px !important'
		}
	},
	tutorialListContainer: {
		width: '100%',
		height: '100%',
		overflowY: 'scroll',
		overflowX: 'hidden',
		marginLeft: theme.spacing(2),
		'& > div': {
			paddingLeft: '0 !important'
		}
	},
	sideVideo: {
		width: '100%',
		borderRadius: '8px',
		cursor: 'pointer'
	},
	videoTitle: {
		fontSize: '16px',
		margin: 0,
		paddingTop: theme.spacing(1),
		'& > span:nth-child(1)': {
			fontWeight: 'bold',
			paddingRight: theme.spacing(0.5)
		},
		'& > span:nth-child(2)': {
			fontSize: '14px'
		}
	},
	// Utils
	spacing: {
		padding: `${theme.spacing(2)}px 0px`
	},
	textBold: {
		fontWeight: 'bold'
	},
	fullHeight: {
		height: '100%'
	}
}));

type Props = {
	tutorialList: { [key: string]: VideoDataType };
};

const TutorialsModal: FC<Props> = ({ tutorialList }) => {
	const classes = useStyles();
	const matchesL = useMediaQuery('(min-width:1700px)');
	const history = useHistory();

	const { isOpenModal, setIsOpenModal, video, setVideo } = useTutorialStore();

	const tutorialListFiltered = Object.values(tutorialList)?.filter(
		(item) => item.name !== video?.name
	);

	const handleClose = () => {
		setIsOpenModal(false);
	};

	const handleGoToTutorials = () => {
		history.push('/tutoriales');
		handleClose();
	};

	const handleSelectSideTutorial = (video: VideoDataType) => {
		setVideo(video);
	};

	return (
		<Dialog
			open={isOpenModal}
			maxWidth='lg'
			disableEscapeKeyDown
			className={classes.container}
			onClose={handleClose}
			PaperProps={{
				style: {
					maxWidth: '1800px',
					width: matchesL ? '92%' : '94%',
					maxHeight: '800px',
					height: matchesL ? '90vh' : '83vh'
				}
			}}
		>
			<div className={classes.cancelCross}>
				<div onClick={handleClose}>
					<X size={16} />
				</div>
			</div>
			<DialogTitle className={classes.dialogTitle}>
				<Grid container alignItems='center' justifyContent='space-between'>
					<Grid item xs={9}>
						<HeadingOne classes={classes.dialogTitle} text={video?.name ?? ''} />
					</Grid>
					{!video?.name?.toLowerCase()?.includes('iniciar') &&
						!video?.name?.toLowerCase()?.includes('registrar') && (
							<Grid item xs={3} container justifyContent='center'>
								<SaveButton onClick={handleGoToTutorials} noIcon>
									Ver todos
								</SaveButton>
							</Grid>
						)}
				</Grid>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.videos}>
					{video && (
						<ReactPlayer
							url={video?.url}
							poster={video?.poster}
							title={video?.name}
							controls
							width={'100%'}
						/>
					)}

					<Grid item container xs={12} sm={3} md={4}>
						<Grid item container className={classes.fullHeight}>
							<Grid item container spacing={2} className={classes.tutorialListContainer}>
								{tutorialListFiltered?.map((item) => (
									<Grid item onClick={() => handleSelectSideTutorial(item)} key={item.name}>
										<video
											src={item?.url}
											title={item?.name}
											poster={item?.poster}
											className={classes.sideVideo}
										></video>
										<p className={classes.videoTitle}>
											<span>{item?.name} -</span>
											<span>{item?.description}</span>
										</p>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default TutorialsModal;
