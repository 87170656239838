import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { maxWidthContainerCard } from '~hooks/useStyles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(3),
		maxWidth: maxWidthContainerCard
	},
	spacing: {
		padding: `${theme.spacing(3)} 0`
	},
	subItemSpacing: {
		padding: `${theme.spacing(1.25)} 0`
	},
	goBack: {
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
	card: {
		padding: theme.spacing(3)
	},
	close: {
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
	// INCIDENTS DOCUMENTS
	upload: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '12px',
		border: 'none !important',
		borderRadius: '50%',
		cursor: 'pointer',
		color: 'white !important',
		'&:hover': {
			backgroundColor: 'rgba(78, 192, 223, 0.08)'
		}
	},
	btnLoader: {
		// minWidth: '100px',
		display: 'flex',
		justifyContent: 'center'
	}
}));
